import React, { Component } from "react";
import { useEffect, useState } from "react";
import Title from "./Title";
import './Title.css';

//import url from '/Sections';

function Home() {
  const [data, setData] = useState("");
  const [loading, setLoading] = React.useState(true);
  const [section, setSection] = useState("");

  useEffect(() => {
    fetch(
      "https://api.scripture.api.bible/v1/bibles/5b835ce16a1703ff-01/books",
      {
        crossDomain: true,
        method: "GET",
        mode: "cors",
        headers: {
          accept: " application/json",
          "api-key": "f728979820c979fbda8050b350cabe6d ",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setLoading(false);
        console.log(data);
      });
  }, []);
  if (loading) {
    return <p>loading please wait</p>;
  }
  return (

    <div className="bible" >
 
     <div style={{backgroundColor:"",width: "491px", float: "left",marginTop:"69px"}}>
     
      <div className="old">
        {Array.from(Array(39), (e, i) => {
          const section = data.data[i].id;
          const url = "https://bible.picturebytesmedia.com/Sections?sectionid=" + section;
         
          return (
          <div>   
            <button style={{ backgroundColor: "#0ebddc", textAlign: "center" ,width: "20rem",
                  height: "5rem",float: "inline-end",marginLeft:"45%"}}>
          <a href={url}><h2>{data.data[i].nameLong} </h2> </a>
         </button>
       </div>
          );
        })}
      </div>{" "}
      <br />{" "}
      </div>
      <div style={{backgroundColor:"",width: "423px", float: "right",marginTop:"69px"}}>
      <div
        className=" new"
        style={{
          width: "100vw",
          display:"grid",
          backgroundColor:""
        }}
      >
        
        {Array.from(Array(66), (e, i) => {
          const section = data.data[i].id;
          const url = "https://bible.picturebytesmedia.com/Sections?sectionid=" + section;
          if (i > 40) {
            return (
             
                <button
                  style={{ backgroundColor: "#0ebddc", textAlign: "center" ,width: "20rem",
                  height: "5rem"}}
                  variant="text"
                >
                  <a href={url}><h2>{data.data[i].nameLong}</h2></a>
                </button>
              
            );
          }
          //const k=chapterdata.chapters[i].chapter_name;
        })}
      </div>
      </div> 
      </div>
    
     
  );
}
export default Home;
//<p>{Array.isArray(data.content[0].items[0])?data.content[0].items[0].map((item)=><p>{item}</p>):null}</p>
