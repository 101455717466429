import React from "react";
import "./Title.css";
const MenuBar = () => {
  return (
    <div>
      <div className="HomeButtons">
        <a className = "navItem" href="Home">Home</a>
        <a className = "navItem" href="About">About</a>
        <a className = "navItem" href="contact">Contact Page</a>
      </div>
    </div>
  );
};

export default MenuBar;
