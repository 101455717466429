import React from 'react';
import {useEffect,useState } from "react";

function Chapters ()
{
  const [data, setData] = useState("");
  const [loading,setLoading]=React.useState(true);
  const qparam =new URLSearchParams(window.location.search);
  const chapterId=qparam.get("chapterid");
  const vcount=qparam.get("vcount")-1;
  const count=qparam.get("vcount");
  

  useEffect(() => {
  fetch('https://api.scripture.api.bible/v1/bibles/5b835ce16a1703ff-01/chapters/'+chapterId+'?content-type=json&include-notes=true&include-titles=true&include-chapter-numbers=true&include-verse-numbers=true&include-verse-spans=false ', {
      crossDomain:true,
      method: 'GET',
      mode:'cors',
      headers: {  "accept":" application/json"  ,
       "api-key": "5686802e0fba309873cbd5605be5acbc"
    }
    })
    .then(response =>response.json())
    .then(
      (data) => {
        setData(data)
        setLoading(false)
        console.log(data)
      })
    },[1]);
    if(loading){
      return <p>loading please wait</p>;
    }
   
  return (
    <div style={{textAlign:"center",
    backgroundColor:'skyblue',
    fontStyle:"bold"
  
      //backgroundImage: 'url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_2qzrjPvjfJbPp9DfkAcgTfEbzbhvMjq9Zw&usqp=CAU)',
      
      
       
    }}>
    
      
         

      {data.data.content.map((item,i) =>data.data.content[i].items.map((e,j)=><p>{data.data.content[i].items[j].text}</p>))}
     

          <div>
            <button style={{ backgroundColor: "green", textAlign: "center" ,width: "9%",
                  height: "70px",marginLeft:"40px" }}>{data.data.previous.id.includes("intro")?<a href ={'https://bible.picturebytesmedia.com/Chapters?chapterid='+data.data.bookId+'.'+vcount+'&vcount='+count} > <h1 style={{color:"white"}}>Previous</h1></a>:  <a href ={'https://bible.picturebytesmedia.com/Chapters?chapterid='+data.data.previous.id+'&vcount='+count} ><h1 style={{color:"white"}}>Previous</h1></a>}</button>
                              <button style={{ backgroundColor: "green", textAlign: "center" ,width: "9%",
                  height: "70px",marginLeft:""}}>
                    {data.data.number.includes(vcount)?<a href ={'https://bible.picturebytesmedia.com/Chapters?chapterid='+data.data.bookId+'.1&vcount='+count} ><h1 style={{color:"white"}}>Next</h1></a>:<a href ={'https://bible.picturebytesmedia.com/Chapters?chapterid='+data.data.next.id+'&vcount='+count} ><h1 style={{color:"white"}}>Next</h1></a>}</button>


          </div>
          
                </div>
  );
  
}  
export default Chapters;
//<p>{Array.isArray(data.content[0].items[0])?data.content[0].items[0].map((item)=><p>{item}</p>):null}</p>
//<button>{data.data.next.map((item,i) =>data.data.next[i].items.map((e,j)=><p>{data.data.next[i].items[j].id}</p>))}</button>
