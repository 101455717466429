import React from 'react';
function Contact() {
    const [hasError,setError]=React.useState(false);
    try{

    }
    catch(error){
      console.log(error)
      setError(true);
    }
    if (hasError) {
      return <p>Sorry, it has some error</p>;
    }
return(
 

<div id="contact-form">
            <row>
           <h6>Name:-  </h6>   
            <input type="text" placeholder="Your Name" />
           <h6> Email Id:-</h6>
            <input type="email" placeholder="Your email address" /><br></br>
            </row>
            <h6>Message:-</h6>
              <textarea placeholder="Your message"></textarea><br></br>
            <button>Send Message</button><br></br>
            <span style={{color: "blue"}}>Thank you for your message, we will be in touch in no time!</span>
        </div>
    
 
    

);

}
export  default Contact;