import React from 'react';
import {useEffect,useState } from "react";
import Title from './Title';
function Sections()
{
  const [data, setData] = useState(""); 
  const [data1, setData1] = useState(""); 
  const [loading,setLoading]=React.useState(true);
  const [load,setLoad]=React.useState(true);
  const qparam =new URLSearchParams(window.location.search);
  const sectionid=qparam.get("sectionid");
  


  useEffect(() => {
    fetch('https://api.scripture.api.bible/v1/bibles/5b835ce16a1703ff-01/chapters/'+sectionid+'.intro?content-type=json&include-notes=true&include-titles=true&include-chapter-numbers=true&include-verse-numbers=true&include-verse-spans=false ', {
        crossDomain:true,
        method: 'GET',
        mode:'cors',
        headers: {  "accept":" application/json"  ,
         "api-key": "5686802e0fba309873cbd5605be5acbc"
      }
      })
      .then(response =>response.json())
      .then(
        (data1) => {
          setData1(data1)
          setLoad(false)
          console.log(data1)
        })
      },[1]);

  useEffect(() => {
    fetch('https://api.scripture.api.bible/v1/bibles/5b835ce16a1703ff-01/books/'+sectionid+'/chapters', {
      crossDomain:true,
      method: 'GET',
      mode:'cors',
      headers: {  "accept":" application/json"  ,
       "api-key": "5686802e0fba309873cbd5605be5acbc"
    }
    })
    .then(response =>response.json())

    
    .then(
      (data) => {
        setData(data)
        setLoading(false)
        console.log(data)
      })
    }, []);
    if(loading || load){
      return <p>loading please wait</p>;
    }
   
  return (
  <div style={{textAlign:"center",
  backgroundColor:' #33ff99 ',
    //backgroundImage: 'url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_2qzrjPvjfJbPp9DfkAcgTfEbzbhvMjq9Zw&usqp=CAU)',
    backgroundSize: "cover",
    height: "100vh",
    color: "#f5f5f5"
  }}>
    <div>
    
    {data.data.map((item,i) => i>0?<a href ={'https://bible.picturebytesmedia.com/Chapters?chapterid='+data.data[i].id+'&vcount='+data.data.length} ><button class="arrange" id={i}>{data.data[i].number}</button></a>:"")}
          </div>
      
        <h6 style={{color:"black"}}>Intro{data1.data.content.map((item,i) =>data1.data.content[i].items.map((e,j)=><p>{data1.data.content[i].items[j].text}</p>))}
     </h6>
         
                </div>
                
                
                
  );
  
}  
export default Sections;
//<p>{Array.isArray(data.content[0].items[0])?data.content[0].items[0].map((item)=><p>{item}</p>):null}</p>
//Array.isArray(data.data)?{data.data.map((i)=>{data.data[i].id})}:null;