import React from 'react';

const About = () => {
    return (
  <h5>hi this is about page </h5>
 
  
    );
  }
  
  export default About;
  
