import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Sections from './pages/Sections';
import About from './pages/About';
import Home from './pages/Home';
import Chapters from './pages/Chapters';
import Title from './pages/Title';
import Footer from './pages/Footer';
import MenuBar from './pages/MenuBar';
import Contact from './pages/contact';


function Getbibleapi()
{
   return (
         
   <div><Title/> 
    
    <Router>
    <Routes>
    <Route path='/About' element={<About/>} />
       <Route path='/Home' element={<Home/>} />
        <Route path='/Sections' element={<Sections/>} />
        <Route path='/Chapters' element={<Chapters/>} />
        <Route path='/Title' element={<Title/>}/>
        <Route path='/MenuBar'element={<MenuBar/>}/>
        <Route path='/Contact'element={<Contact/>}/>

    </Routes>
    </Router>
     
    <Footer/>
   </div>              
  );
  
}  
export default Getbibleapi;
//<p>{Array.isArray(data.content[0].items[0])?data.content[0].items[0].map((item)=><p>{item}</p>):null}</p>