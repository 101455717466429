import React from 'react'
import './Title.css';
const Footer = () => {
  return (
    <div>
      <div className="Footer">
      <h1>Footer</h1>
      <div className="Footers"></div>
    </div>
    </div>
  )
}

export default Footer
